import React from 'react';
import { Link } from 'gatsby';
import ReactMarkdown from 'react-markdown';
import PropTypes from 'prop-types';

import { localizePath } from '../../utils/localize';
import CustomLink from '../CustomLink';
import useSciencesData from '../../queries/sciencesQueries';
import useScienceSubFieldData from '../../queries/scienceSubFieldQuery';

const SciencesDetailPage = (props) => {
  const { pageContext } = props;
  const sciences = useSciencesData();
  const scienceSubField = useScienceSubFieldData();
  const langSciencesData = sciences.filter(
    (item) => item.locale === props.data.locale,
  );
  const langScienceSubFieldData = scienceSubField.filter(
    (item) => item.locale === props.data.locale,
  );
  return (
    <div>
      <div className="main-wrapper col-lg-7 col-12 d-lg-flex mx-auto">
        <div className="left-container-wrapper col-lg-3 col-12 py-5">
          <div className="accordion accordion-flush" id="accordionFlushExample">
            {langSciencesData.map((science) => (
              <div className="bg-transparent accordion-item" key={science.id}>
                <h2 className="accordion-header" id={science.headingDropdownId}>
                  <button
                    className="shadow-none accordion-button bg-transparent collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target={`#${science.accordionId}`}
                    aria-expanded="false"
                    aria-controls="flush-collapse"
                  >
                    <Link to={science.slug}>{science.title}</Link>
                  </button>
                </h2>
                <div
                  id={science.accordionId}
                  className="accordion-collapse collapse"
                  aria-labelledby={science.accordionId}
                  data-bs-parent="#accordionFlushExample"
                >
                  <div className="accordion-body">
                    <ul className="group-links">
                      {langScienceSubFieldData
                        .filter(
                          (item) => item.scienceSubId === science.scienceId,
                        )
                        .map((item) => (
                          <li className="links pt-1" key={item.id}>
                            <CustomLink
                              className="language-text"
                              link={{
                                url: `${localizePath({
                                  ...item,
                                  ...pageContext,
                                  isPreview: false,
                                  slug: item.slug,
                                })}`,
                              }}
                              to={`/${item.slug}`}
                            >
                              <a className="link">{item.title}</a>
                            </CustomLink>
                          </li>
                        ))}
                    </ul>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className="right-container-wrapper py-5 ms-lg-5 col-lg-9 col-12">
          <div className="title-container mb-2 ">
            <h4 className="title-text col-lg-11 ms-lg-4 me-lg-0 ms-2 me-2">
              {props.data.title}
            </h4>
          </div>
          <div className="content-wrapper ms-lg-0 me-lg-0 ms-2 me-2">
            <ReactMarkdown className="content-text">
              {props.data.content}
            </ReactMarkdown>
          </div>
        </div>
      </div>
    </div>
  );
};

SciencesDetailPage.propTypes = {
  data: PropTypes.shape({
    title: PropTypes.string.isRequired,
    locale: PropTypes.string.isRequired,
    science: PropTypes.string.isRequired,
    scienceSubId: PropTypes.string.isRequired,
    slug: PropTypes.string.isRequired,
    date: PropTypes.string.isRequired,
    content: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
  }).isRequired,
  pageContext: PropTypes.shape({
    locale: PropTypes.string.isRequired,
    defaultLocale: PropTypes.string.isRequired,
    localizedPaths: PropTypes.arrayOf(
      PropTypes.shape({
        url: PropTypes.string,
        href: PropTypes.string,
      }),
    ).isRequired,
  }).isRequired,
};

export default SciencesDetailPage;
