import { graphql, useStaticQuery } from 'gatsby';

const useScienceSubFieldData = () => {
  const {
    allStrapiScienceSubFields: { nodes: scienceSubField },
  } = useStaticQuery(graphql`
    {
      allStrapiScienceSubFields(sort: { order: ASC, fields: strapiId }) {
        nodes {
          id
          content
          locale
          localizations {
            id
            locale
          }
          science {
            id
            locale
          }
          slug
          title
          scienceSubId
        }
      }
    }
  `);
  return scienceSubField;
};

export default useScienceSubFieldData;
