import { graphql, useStaticQuery } from 'gatsby';

const useSciencesData = () => {
  const {
    allStrapiSciences: { nodes: sciences },
  } = useStaticQuery(graphql`
    {
      allStrapiSciences(sort: {order: ASC, fields: strapiId}) {
        nodes {
          accordionId
          headingDropdownId
          id
          locale
          localizations {
            id
            locale
          }
          scienceId
          science_sub_fields {
            content
            id
            locale
            metaData {
              id
              metaDescription
              metaTitle
            }
            science
            scienceSubId
            slug
            title
          }
          strapiId
          title
        }
      }
    }
  `);
  return sciences;
};

export default useSciencesData;
